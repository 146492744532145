import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-folio',
  templateUrl: './search-folio.component.html',
  styleUrls: ['./search-folio.component.css']
})
export class SearchFolioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
