import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-home',
  templateUrl: './banner-home.component.html',
  styleUrls: ['./banner-home.component.css']
})

export class BannerHomeComponent implements OnInit {

  public title: string;

  constructor() { 
    this.title = 'Últimos artículos';
  }

  ngOnInit(): void {
  }

}
