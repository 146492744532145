<section class="w-100 section">
    <div class="contenedor">
        <div class="contenedor-cuadros">
            <div (click)="select(tab)" class="cuadrito" *ngFor="let tab of tabs" style="background-image: url({{tab.img}}); background-size: cover;">
                <div class="description" [ngClass]="{ 'description-selected': tab.selected }">
                    {{tab.description}}
                </div>
            </div>
        </div>
        <div *ngFor="let tab of tabs">
            <div class="contenedor-card" *ngIf="tab.selected">
                <div class="my-card">
                    <div class="card-image" style="background-image: url({{tab.img_card}}); background-size: cover;"></div>
                    <div class="d-flex flex-column mt-md-5 mt-sm-1">
                        <div class="contenedor-text" *ngFor="let card of tab.card">
                            <div class="title">{{card.title}}</div>
                            <div class="subtitle">{{card.subtitle}}</div>
                            <div class="text" *ngFor="let p of card.text">{{ p.newline }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>