import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @Input() dataModal: any;

  visible: boolean = true

  constructor(
    @Inject(DOCUMENT) document: Document,
    
  ) { }

  ngOnInit(): void {
  }
  
  setModalData( event: any ){
    
    let click = (document.getElementById('idModal') as HTMLElement);
    click.click();

    let modalTitle: HTMLElement = document.getElementById('modal-title') as HTMLElement;
    let modalsubtitle1: HTMLElement = document.getElementById('modal-subtitle1') as HTMLElement;
    let modaldescription1: HTMLElement = document.getElementById('modal-description1') as HTMLElement;
    let modalsubtitle2: HTMLElement = document.getElementById('modal-subtitle2') as HTMLElement;
    let modaldescription2: HTMLElement = document.getElementById('modal-description2') as HTMLElement;
    let modalNote: HTMLElement = document.getElementById('modal-notes') as HTMLElement;

    modalTitle.innerHTML         = event.procedure;
    modalsubtitle1.innerHTML     = event.subtitulo1;
    modaldescription1.innerHTML  = event.lista1;
    modalsubtitle2.innerHTML     = event.subtitulo2;
    modaldescription2.innerHTML  = event.lista2;
    modalNote.innerHTML          = event.nota;    
  }

  showRecommendation(){
    let click = (document.getElementById('idRecommendationModal') as HTMLElement);
    click.click();
  }


  setSidebarPopup(){
    this.visible = this.visible = !this.visible;
  }


}