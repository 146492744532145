<div class="d-flex justify-content-center">
    <div class="gestocar-container">  
        <app-banner-home></app-banner-home>
        <app-certification></app-certification>
        <app-located></app-located>
        <app-clients></app-clients>
        <app-search-folio></app-search-folio>
        <app-search-record (onNewProcedure)="setModalData( $event )" (onNewRecommendation)="showRecommendation()"></app-search-record>
        <app-about-us></app-about-us>
        <app-our-software></app-our-software>
        <app-blog-carousel></app-blog-carousel>
        <app-remote-service></app-remote-service>
        <app-catalogue></app-catalogue>
        <app-contact-form></app-contact-form>
        <app-agreements></app-agreements>
        <app-payment-methods></app-payment-methods>
    </div>
</div>

<!-- <a class="btn-gesto-text" (click)="setSidebarPopup()" *ngIf="visible">
    <i class="fa-solid fa-arrow-right"></i>
</a>


<a class="btn-gesto-text" (click)="setSidebarPopup()" *ngIf="!visible">
    <i class="fa-solid fa-arrow-left"></i>
</a>


<a class="btn-gesto" *ngIf="visible">
    <img src="../../../../assets/images/banner-flotante.png" alt="" class="img-fluid">
</a> -->


<a class="btnF-wsp" target="blank" href="https://api.whatsapp.com/send?phone=5215540250459&text=Buenas%20tardes%2C%20me%20gustar%C3%ADa%20recibir%20informaci%C3%B3n%20acerca%20de%20sus%20tr%C3%A1mites%20vehiculares%20y%20administraci%C3%B3n%20de%20flotillas%20vehiculares.%20*Enviado%20desde%20p%C3%A1gina%20web*">
    <i class="fa-brands fa-whatsapp"></i>
</a>

<p class="text-wsp">¿Quieres más información? <br> <b>escribenos...</b></p>


<button type="button" id="idModal" class="btn btn-modal" data-bs-toggle="modal" data-bs-target="#ProcedureModal" data-bs-whatever="@getbootstrap"></button>
<button type="button" id="idRecommendationModal" class="btn btn-modal" data-bs-toggle="modal" data-bs-target="#RecommendationModal" data-bs-whatever="@getbootstrap"></button>
    
<div class="modal fade" id="ProcedureModal" tabindex="-1" aria-labelledby="ProcedureModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <div class="img-container">
                <img src="../../../../assets/images/gestocar-icon.png" alt="" class="img-fluid">
            </div>
            <a href="#contacto">
                <button class="btn btn-cotizar">Cotizar</button>
            </a>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" id="modalBody">
            <div class="modal-title" id="modal-title"></div>
            <div class="modal-subtitle" id="modal-subtitle1"></div>
            <div class="modal-description" id="modal-description1"></div>
            <div class="modal-subtitle" id="modal-subtitle2"></div>
            <div class="modal-description" id="modal-description2"></div>
            <div class="modal-notes" id="modal-notes"></div>
        </div>           
        </div>
    </div>
</div>

<div class="modal fade" id="RecommendationModal" tabindex="-1" aria-labelledby="RecommendationModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <div class="img-container">
                <img src="../../../../assets/images/gestocar-icon.png" alt="" class="img-fluid">
            </div>           
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" id="modalBody"> 
            <div class="d-block text-center">
                <h3>Recomendaciones que debes de llevar acabo</h3>
            </div>            
            <ol>
                <li>Verifica el estatus de tu placa en finanzas.</li>
                <li>Consulta que los pagos de tenencia correspondan al numero de tu placa.</li>
                <li>Valida tu factura.</li>
                <li>Certifica tus tenencias.</li>
                <li>Si diste de alta tus placas en otro estado, verifica tu baja vehicular correspondiente.</li>
                <li>Si compraste tu auto a finales de año, te deben entregar el pago de tenencia proporcional.</li>
                <li>Persona moral (empresa) siempre paga tenencia.</li>
                <li>Si compras ó vendes tu auto, debes realizar un contrato de compra-venta.</li>
                <li>Siempre obtén el INE del comprador ó vendedor.</li>
            </ol>    
            <div class="d-block text-center mt-4">
                <strong class="mt-2"> Indicaciones para envió de documentos</strong>
                <p class="mt-2">Solicitamos la documentación en PDF y tamaño original. No aceptamos fotos ni escaneos de teléfono.</p>   
            </div>         
        </div>           
        </div>
    </div>
</div>

