import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-located',
  templateUrl: './located.component.html',
  styleUrls: ['./located.component.css']
})
export class LocatedComponent implements OnInit {

  constructor() { }


  customOptions: any = {
    nav: false,
    loop: true,
    margin: 10,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5200,
    autoplayTimeout: 5280,
    smartSpeed: 5200,
    autoplayHoverPause: false,
    slideTransition: 'linear',
    responsive: {
        0: {
          items: 1            
        },
        576: {
          items: 2
        },
        768: {
          items: 3
        },
        992: {
          items: 4
        },
        1200: {
          items: 4
        },
        1400: {
          items: 5
        }
    }
  }

  clients = [
    {
      path:  '../../assets/images/clients/Cdmx.png',
      title: 'Cdmx'
    },
    {
      path: '../../assets/images/clients/Campeche.png',
      title: 'Campeche'
    },
    {
      path:  '../../assets/images/clients/Chihuahua.png',
      title: 'Chihuahua'
    },
    {
      path:  '../../assets/images/clients/Coahuila.png',
      title: 'Coahuila'
    },
    {
      path:  '../../assets/images/clients/Edomex.png',
      title: 'Edomex'
    },
    {
      path:  '../../assets/images/clients/Guanajuato.png',
      title: 'Guanajuato'
    },
    {
      path:  '../../assets/images/clients/Guerrero.png',
      title: 'Guerrero'
    },
    {
      path:  '../../assets/images/clients/Hidalgo.png',
      title: 'Hidalgo'
    },
    {
      path:  '../../assets/images/clients/Jalisco.png',
      title: 'Jalisco'
    },
    {
      path:  '../../assets/images/clients/Michoacan.png ',
      title: 'Michoacán'
    },
    {
      path:  '../../assets/images/clients/Morelos.png',
      title: 'Morelos'
    },
    {
      path:  '../../assets/images/clients/Nuevo-Leon.png',
      title: 'Nuevo León'
    },
    {
      path:  '../../assets/images/clients/Oaxaca.png',
      title: 'Oaxaca'
    },
    {
      path:  '../../assets/images/clients/Puebla.png',
      title: 'Puebla'
    },
    {
      path:  '../../assets/images/clients/Queretaro.png',
      title: 'Querétaro'
    },
    {
      path:  '../../assets/images/clients/San-Luis-Potosi.png',
      title: 'San Luis Potosí'
    },
    {
      path:  '../../assets/images/clients/Sonora.png',
      title: 'Sonora'
    },
    {
      path:  '../../assets/images/clients/Tabasco.png',
      title: 'Tabasco'
    },
    {
      path:  '../../assets/images/clients/Tamaulipas.png',
      title: 'Tamaulipas'
    },
    {
      path:  '../../assets/images/clients/Veracruz.png',
      title: 'Veracruz'
    }
  ]
  
  ngOnInit(): void {
  }

}
