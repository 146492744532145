<section id="remoto" class="m-0 p-0">
    <div class="row w-100 p-0 m-0 aling-items-center">
       <div class="col-12 col-md-7 col-lg-6">
            <div class="image-container">
                <img src=".././../../../assets/images/remote-service/motorbike.png" class="img-fluid" alt="">
            </div>
       </div>
       <div class="col-12 col-md-5 col-lg-6 d-flex flex-column justify-content-center">           
        <span>Remoto</span>
        <p class="mt-3"> En GESTOCAR contamos con un servicio </p>
        <p> de gruas exclusivo para motos. </p>
        <div class="d-block mb-5 mb-md-0">
            <a href="#contacto"><button class="btn btn-remote mt-3">CONTRATA AQUÍ</button></a>
        </div>                    
       </div>
    </div>
</section>