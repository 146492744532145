import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/page/page.module').then( m => m.PageModule ),        
    },
    {
        path: '**',
        redirectTo: ''
    }
];

//Exportar el modulo de rutas
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);