<section class="software">
  <div class="row my-row d-flex justify-content-center">
    <div class="myCol col-lg-11 col-md-11 col-sm-12">
        <div class="row" id="contenido">
          <div class="col-lg-2 col-md-2 col-sm-12">
            <h2 class="texto-grande">NUES<br>TRO SOFT<br>WARE</h2>
            <p class="text-software">NUESTRO SOFTWARE</p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <img class="Software" src="../../assets/images/software-gestocar-mockup.png" alt="" />
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="carousel-container">
              <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>
                          <div class="container-fluid">
                            <div class="texto">
                              <h2 class="title">Innovación:</h2>
                              <p>Contamos con un Software desarrollado bajo estándares internacionales que te ayudarán a darle seguimiento a tus trámites en tiempo real</p>
                        </div>
                          </div>
                    </ng-template>
                    <ng-template carouselSlide>
                          <div class="container-fluid">
                            <div class="texto">
                              <h2 class="title">Historial:</h2>
                              <p>Nuestro software te permitirá tener un historial de tus trámites realizados disponibles en todo momento</p>
                        </div>
                          </div>
                    </ng-template>
                    <ng-template carouselSlide>
                          <div class="container-fluid">
                            <div class="texto">
                              <h2 class="title">Seguridad:</h2>
                              <p>Tu información siempre estará protegida y disponible para agilizar futuros trámites con nosotros</p>
                        </div>
                          </div>
                    </ng-template>

              </owl-carousel-o>
        </div>
          </div>
        </div>          
    </div> 
 </div>
</section>

