import { Injectable } from '@angular/core';
import { withLatestFrom } from 'rxjs';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor() { }
  
  showNotification(type: SweetAlertIcon, message: string){    
    const Toast = Swal.mixin({
      toast: true,
      timerProgressBar: true,
      showCloseButton: true,
      showConfirmButton: false,
      position: 'top', 
      width: '80%',    
      timer: 3500,      
      customClass: {
        popup: 'ui-popup-'+type
      },

      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon:  type,
      title: message
    })  
  }
  loading(isLoading: boolean, title?: String) {
    if(isLoading) {
      Swal.fire({
        heightAuto: false,
        title: title ?? 'Cargando...',
        html: 'Cargando...',
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        didClose: () => {
          Swal.hideLoading();
        }
      });
    } else {
      Swal.close();
    }
  }

}
