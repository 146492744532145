import { Injectable } from '@angular/core';
import { ProceduresListMock, tramitesPorEstado } from '@data/constants/procedures';

@Injectable({
  providedIn: 'root'
})
export class ProceduresService {

  constructor() { }
  async getProcedures(){      
    return new Promise( async (resolve) => {
      let ProceduresData = ProceduresListMock;  
      ProceduresData == undefined ? resolve({ok: false}): resolve({info: ProceduresData, ok: true });
    });    
  }

  async getProceduresByState(state: string){ 
    type ObjectKey = keyof typeof tramitesPorEstado;
    const myKey = state as ObjectKey;   
    return new Promise( async (resolve) => {
      let ProceduresData = tramitesPorEstado[myKey]  
      ProceduresData == undefined ? resolve({ok: false}): resolve({info: ProceduresData, ok: true });
    });    
  }

  getRequirements(state: string, requirement: number){ 
    type ObjectKey = keyof typeof ProceduresListMock;
    const myState = state as ObjectKey;     
    
      let ProceduresData = ProceduresListMock[myState]; 
     
      let newData= this.getObject(ProceduresData, requirement);
     
      return newData;
       
  }
             //  CDMX        0
  getObject(data:any, key:number) {
    let selectedConstant = data.filter((c:any) =>{ return c.id === key; })[0];
    

    return selectedConstant;
  }

  /* tramitesList['CDMX'][`${}`] */
}
