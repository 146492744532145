<section>
      <div class="texto">
            <h2 class="title text-center mt-3">Estados en los que nos encontramos:</h2>
      </div>
      <div class="section-carousel">
            <owl-carousel-o [options]="customOptions">
                <ng-container *ngFor="let client of clients">
                  <ng-template carouselSlide >
                    <div class="img-container">
                        <img [src]="client.path" [alt]="client.title" class="img-fluid">
                    </div>            
                  </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
</section>
