import { Component, OnInit } from '@angular/core';
import { BlogI, ListBlogI } from '@data/interfaces/blog';
import { BlogService } from '@data/services/api/blog.service';

@Component({
  selector: 'app-blog-carousel',
  templateUrl: './blog-carousel.component.html',
  styleUrls: ['./blog-carousel.component.css']
})
export class BlogCarouselComponent implements OnInit {

  constructor(
    private blogService: BlogService
  ) { }
  
  dataBlog: any;
  
  myBlogData: any;

  public cadena!: string;

  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  async ngOnInit() {
    let getDataBlogs: any;
    getDataBlogs = this.blogService.getData().subscribe({
      next: (response) => {
       this.dataBlog = response;
       
      },
      complete: () => {

      },
      error:(err) => {

      }

    });
  }


}
