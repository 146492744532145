import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { blogsListMock } from '@constants/blogs-data';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  constructor(
    private http: HttpClient
  ) { }
  
  
  getData(){      
    // return new Promise( async (resolve) => {
    //   let blogsData = blogsListMock;      
    //   blogsData == undefined ? resolve({ok: false}): resolve({info: blogsData, ok: true });
    // });    
    const scope: string = `https://servicios2.gestocar.mx/wp-json/wp/v2/posts`;
    return this.http.get<any>(`${scope}`);
  }
}
