import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '@data/services/contact.service';
import { UiService } from '@data/services/api/ui.service';
import { TokenResponse } from '@data/interfaces/contact.interface';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private uiService: UiService,
    @Inject(DOCUMENT) private document: any
  ) { }
 
  ngOnInit(): void { 
  }

  formContact: FormGroup = this.formBuilder.group({
    // Datos personales 
    name:         [null, [Validators.required]],
    email:        [null, [Validators.required, Validators.email]],  
    subject:      [null, [Validators.required]],
    message:      [null, [Validators.required]],
    phone:        [null, [Validators.required]]
  });

  onSubmit(){

    this.uiService.loading(true, 'Enviando correo...');
    let nombre: string = this.formContact.value.name;
    let email: string = this.formContact.value.email;
    let subject: string = this.formContact.value.subject;   
    let message: string = this.formContact.value.message;
    let phone: number = this.formContact.value.phone;

    this.contactService.getMailToken().subscribe({
      next: (response: TokenResponse)=>{
        let  token: string = 'Bearer ' + response.token;        
        this.contactService.sendMail(nombre, phone, email, subject, message, token).subscribe({
          complete: ()=>{
            this.uiService.loading(false);
            this.uiService.showNotification('success','La información ha sido enviada correctamente, en breve nos comunicaremos con usted.');
          }, 
          error: ()=>{
            this.uiService.loading(false);
            this.uiService.showNotification('error','Ocurrio un problema al tratar de envair la información, por favor intentelo más tarde.');    
          }
        });
      },
      error: (error) =>{
        this.uiService.loading(false);
        this.uiService.showNotification('error', 'Ocurrio un problema al tratar de envair la información, por favor intentelo más tarde.');
      }
    });    
  }

}
