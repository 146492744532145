import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { BannerHomeComponent } from './banner-home/banner-home.component';
import { BlogCarouselComponent } from './blog-carousel/blog-carousel.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { CertificationComponent } from './certification/certification.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { HomeComponent } from './home/home.component';
import { LocatedComponent } from './located/located.component';
import { OurSoftwareComponent } from './our-software/our-software.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { SearchFolioComponent } from './search-folio/search-folio.component';
import { SearchRecordComponent } from './search-record/search-record.component';


const routes: Routes = [
    {
      path: '',
      children: [
        { path: 'home', component: HomeComponent},
        { path: '**', redirectTo: 'home' } 
      ]
    }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PageRoutingModule { }
