<section class="section-image w-100 d-flex flex-row justify-content-center flex-wrap" id="nuestros-servicios">
    
    <div class="botones h-100 d-flex flex-column justify-content-between">
        <div class="titulo d-flex flex-row justify-content-center"><span class="m-4">NUESTROS SERVICIOS</span></div>
    <div class="d-flex flex-row justify-content-md-end justify-content-center my-4">
        <div class="myCard">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <div class="contenedor-texto">
                            <p class="titulo-card">PARTICULARES</p>
                            <p class="texto">
                                <li>ALTA DE PLACAS VEHICULOS NUEVOS</li>
                                <li>ALTA DE PLACAS VEHICULOS USADOS</li>
                                <li>BAJA DE PLACAS POR SINIESTRO</li>
                                <li>CAMBIO DE PROPIETARIO</li>
                                <li>RENOVACIÓN DE TARJETA DE CIRCULACIÓN</li>
                                <li>FOTOMULTAS</li>
                                <li>PERMISO ANTRA</li>
                                <li>REMPLACAMIENTO</li>
                                <li>REFRENDO EN MORELOS</li>
                                <li>AUTOS CLASICOS</li>
                                <li>DISCAPACIDAD</li>
                                <li>VERIFICACION DE AUTOS</li>
                            </p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                        aria-labelledby="pills-profile-tab">
                        <div class="contenedor-texto">
                            <p class="titulo-card">AUTOS DE CARGA</p>
                            <p class="texto">
                                <li>ALTA TRANSPORTE NUEVO</li>
                                <li>ALTA TRANSPORTE USADO</li>
                                <li>ALTA REMOLQUES</li>
                                <li>REVISTA VEHICULAR</li>
                                <li>CAMBIO DE PROPIETARIO</li>
                                <li>REMPLACAMIENTO</li>
                                <li>BAJA DE PLACAS</li>
                                <li>PERMISO ANTRA</li>
                            </p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                        aria-labelledby="pills-contact-tab">
                        <div class="contenedor-texto">
                            <p class="titulo-card">EMPRESAS</p>
                            <p class="texto">
                                <li>VERIFICACION DE AUTOS</li>
                                <li>LIBERACION DE CORRALÓN</li>
                                <li>AVREDITACIÓN DE PROPIEDAD</li>
                                <li>ADMINISTRACIÓN DE FLOTILLAS</li>
                                <li>TRASLADOS DE AUTOS</li>
                                <li>IMPUGNACION DE MULTAS</li>
                                <li>DEVOLUCIÓN DE PAGO EN FINANZAS</li>
                                <li>CONSTANCIA DE ADEUDOS PARA LICITACIÓN</li>
                                <li>TRASLADOS DE EJECUTIVOS</li>
                            </p>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-sct" role="tabpanel"
                        aria-labelledby="pill-sct-tab">
                        <div class="contenedor-texto">
                            <p class="titulo-card">SCT</p>
                            <p class="texto">
                                <li>ALTA PERSONA FÍSICA PRIMERA VEZ</li>
                                <li>ALTA EMPRESA PRIMERA VEZ</li>
                                <li>EXPEDICIÓN DE PLACAS</li>
                                <li>REMOLQUES</li>
                                <li>BAJA DE PLACAS</li>
                                <li>CAMBIO DE PROPIETARIO</li>
                                <li>LICENCIAS PARA CHOFER</li>
                            </p>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <div class="w-100">
        <ul class="d-flex nav nav-pills flex-row w-100" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active w-100" id="pills-home-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                    aria-selected="true">GESTORIA Y TRÁMITES PARTICULARES</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link w-100" id="pills-profile-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-profile" type="button" role="tab"
                    aria-controls="pills-profile" aria-selected="false">AUTOS DE CARGA</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link w-100" id="pills-contact-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-contact" type="button" role="tab"
                    aria-controls="pills-contact" aria-selected="false">SERVICIOS DE EMPRESAS</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link w-100" id="pills-sct-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-sct" type="button" role="tab" aria-controls="pills-sct"
                    aria-selected="false">SCT</button>
            </li>
        </ul>
    </div>
    </div>
</section>


<!--<section class="d-block mt-5 img-fluid" id="nuestros-servicios">
    <div class="row my-row align-items-center justify-content-center">
        <div class="myCol col-lg-10 col-md-10 col-sm-12 text-center img-fluid">
            <h2 class="titulo-principal">NUESTROS SERVICIOS</h2>
            <div class="row">
                <div class="d-flex justify-content-end">
                    <div class="card col-4">
                        <div class="card-body">
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                    aria-labelledby="pills-home-tab">
                                    <div class="contenedor-texto">
                                        <p class="titulo">PARTICULARES</p>
                                        <p class="texto">
                                            <li>ALTA DE PLACAS VEHICULOS NUEVOS</li>
                                            <li>ALTA DE PLACAS VEHICULOS USADOS</li>
                                            <li>BAJA DE PLACAS POR SINIESTRO</li>
                                            <li>CAMBIO DE PROPIETARIO</li>
                                            <li>RENOVACIÓN DE TARJETA DE CIRCULACIÓN</li>
                                            <li>FOTOMULTAS</li>
                                            <li>PERMISO ANTRA</li>
                                            <li>REMPLACAMIENTO</li>
                                            <li>REFRENDO EN MORELOS</li>
                                            <li>AUTOS CLASICOS</li>
                                            <li>DISCAPACIDAD</li>
                                            <li>VERIFICACION DE AUTOS</li>
                                        </p>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                    aria-labelledby="pills-profile-tab">
                                    <div class="contenedor-texto">
                                        <p class="titulo">AUTOS DE CARGA</p>
                                        <p class="texto">
                                            <li>ALTA TRANSPORTE NUEVO</li>
                                            <li>ALTA TRANSPORTE USADO</li>
                                            <li>ALTA REMOLQUES</li>
                                            <li>REVISTA VEHICULAR</li>
                                            <li>CAMBIO DE PROPIETARIO</li>
                                            <li>REMPLACAMIENTO</li>
                                            <li>BAJA DE PLACAS</li>
                                            <li>PERMISO ANTRA</li>
                                        </p>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                                    aria-labelledby="pills-contact-tab">
                                    <div class="contenedor-texto">
                                        <p class="titulo">EMPRESAS</p>
                                        <p class="texto">
                                            <li>VERIFICACION DE AUTOS</li>
                                            <li>LIBERACION DE CORRALÓN</li>
                                            <li>AVREDITACIÓN DE PROPIEDAD</li>
                                            <li>ADMINISTRACIÓN DE FLOTILLAS</li>
                                            <li>TRASLADOS DE AUTOS</li>
                                            <li>IMPUGNACION DE MULTAS</li>
                                            <li>DEVOLUCIÓN DE PAGO EN FINANZAS</li>
                                            <li>CONSTANCIA DE ADEUDOS PARA LICITACIÓN</li>
                                            <li>TRASLADOS DE EJECUTIVOS</li>
                                        </p>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-sct" role="tabpanel"
                                    aria-labelledby="pill-sct-tab">
                                    <div class="contenedor-texto">
                                        <p class="titulo">SCT</p>
                                        <p class="texto">
                                            <li>ALTA PERSONA FÍSICA PRIMERA VEZ</li>
                                            <li>ALTA EMPRESA PRIMERA VEZ</li>
                                            <li>EXPEDICIÓN DE PLACAS</li>
                                            <li>REMOLQUES</li>
                                            <li>BAJA DE PLACAS</li>
                                            <li>CAMBIO DE PROPIETARIO</li>
                                            <li>LICENCIAS PARA CHOFER</li>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 justify-content-center">
                        <ul class="nav nav-pills align-items-center justify-content-center" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">GESTORIA Y TRÁMITES PARTICULARES</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-profile" type="button" role="tab"
                                    aria-controls="pills-profile" aria-selected="false">AUTOS DE CARGA</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-contact" type="button" role="tab"
                                    aria-controls="pills-contact" aria-selected="false">SERVICIOS DE EMPRESAS</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-sct-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-sct" type="button" role="tab" aria-controls="pills-sct"
                                    aria-selected="false">SCT</button>
                            </li>
                        </ul>
            </div>
            </div>
        </div>
    </div>
</section>-->

