import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  public tabs : any [] = [
    {
      img : '../../assets/images/Tesla_front_nosotros_cuadrado.jpg',
      img_card : '../../assets/images/Tesla_Front_rectangular.jpg',
      selected : true,
      description : 'Nosotros',
      card : [
        {
          title : 'Nuestra prioridad es',
          subtitle : 'CUIDAR TU TIEMPO',
          text : [
            {
              newline: `Somos una empresa de excelencia en gestoría vehícular ante las
              dependencias gubernamentales.
              Nos encargamos por ti de atender las necesidades de
              mantenimiento y mejora de tu automóvil para asegurar su perfecto
              funcionamiento.`
            },
            {
              newline: `Te brindamos asesoría en siniestros y trámites con aseguradoras;
              así como asistencia víal si no cuentas con un seguro.`
            }
          ]         
        }
      ]
    },

    {
      img : '../../assets/images/Mclaren_front_cuadrado.jpg',
      img_card : '../../assets/images/Mclaren_front_rectangular.jpg',
      selected : false,
      description : 'Misión y Visión',
      card : [
        {
          title : 'MISIÓN',
          text : [
            {
              newline: `Ser reconocidos en el mercado por la confianza ganada con
              nuestros clientes, al garantizar soluciones éticas con las
              herramientas necesarias,
              de manera providencial en tiempo y forma.`
            }
          ]
        },

        {
          title : 'VISIÓN',
          text : [
            {
              newline: `Lograr la entera satisfacción de nuestros clientes con
              soluciones fiables y duraderas en cuanto a mantenimiento,
              seguridad y trámites relacionados
              con su automóvil. Respetando su criterio y al mismo tiempo
              asesorándolos con nuestra experiencia y conocimiento profundo,
              para que tomen las mejores
              decisiones sobre su vehículo`
            }
          ]
        }
      ]
    },

    {
      img : '../../assets/images/Tesla_2_front_cuadrado.jpg',
      img_card : '../../assets/images/Tesla_front_2_rectangular.jpg',
      selected : false,
      description : 'Valores',
      card : [
        {
          title : 'Nuestros Valores',
          text : [
            {              
              newline : `Nuestro principal propósito es velar por la satisfacción de
              nuestros clientes con:`
            },
            {
              newline: '- Honestidad'
            },
            {
              newline: '- Servicio'
            },
            {
              newline: '- Responsabilidad'
            },
            {
              newline: '- Excelencia'
            },
            {
              newline: '- Respeto'
            }
          ]
        }
      ]
    }


  ];

  constructor() { }

  ngOnInit(): void {
  }

  select(tab: any) {
    this.tabs.map(x => x.selected = false);
    tab.selected = true;
  }

}
