<section id="catalogo">
    <div class="row my-row d-flex justify-content-center" id="">
        <div class="myCol col-lg-10 col-md-11 col-sm-11 d-flex justify-content-center">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-6 col-md-6 col-12" id="contenido-texto">
                    <div class="mensaje">
                        <h2>Compra tu auto <br>con nosotros.</h2>
                        <p>Tenemos un catalogo con autos de calidad y precio</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12" id="contenido-imagen">
                    <img class="clienteVenta" src="../../assets/images/Cliente_venta_auto.png" alt="">
                </div>
                <div class="col-12">
                    <a href="https://servicios2.gestocar.mx/"> <button type="button" class="btn btn-catalogo">Visitar Catalogo</button></a>
                </div>
            </div>
        </div>
    </div>
</section>