import { NgModule } from '@angular/core';
import { PageRoutingModule } from './page-routing.module';

import { CommonModule } from '@angular/common';
import { BannerHomeComponent } from './banner-home/banner-home.component';
import { CertificationComponent } from './certification/certification.component';
import { LocatedComponent } from './located/located.component';
import { ClientsComponent } from './clients/clients.component';
import { SearchFolioComponent } from './search-folio/search-folio.component';
import { SearchRecordComponent } from './search-record/search-record.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurSoftwareComponent } from './our-software/our-software.component';
import { BlogCarouselComponent } from './blog-carousel/blog-carousel.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { CardBlogCarouselComponent } from './blog-carousel/components/card-blog-carousel/card-blog-carousel.component';
import { HomeComponent } from './home/home.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RemoteServiceComponent } from './remote-service/remote-service.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    BannerHomeComponent,
    CertificationComponent,
    LocatedComponent,
    ClientsComponent,
    SearchFolioComponent,
    SearchRecordComponent,
    AboutUsComponent,
    OurSoftwareComponent,
    BlogCarouselComponent,
    CatalogueComponent,
    ContactFormComponent,
    AgreementsComponent,
    PaymentMethodsComponent,
    CardBlogCarouselComponent,
    HomeComponent,
    RemoteServiceComponent,
    /*/CarouselHolderComponent*/
  ],
  imports: [
    CommonModule,
    PageRoutingModule,
    CarouselModule,
    ReactiveFormsModule,
  ],
  exports: [
    BannerHomeComponent,
    CertificationComponent,
    LocatedComponent,
    ClientsComponent,
    SearchFolioComponent,
    SearchRecordComponent,
    AboutUsComponent,
    OurSoftwareComponent,
    BlogCarouselComponent,
    CatalogueComponent,
    ContactFormComponent,
    AgreementsComponent,
    PaymentMethodsComponent,
    CardBlogCarouselComponent,
    RemoteServiceComponent
  ]
})
export class PageModule { }
