<div class="card"  *ngIf="blogData != undefined">
    <img src="{{ get_url(blogData.content.rendered) }}" class="gestocard-img-top" alt="...">
    <div class="card-body">
      <h5 class="card-title">{{ blogData.title.rendered | uppercase }}</h5>
      <p class="card-text">{{ blogData.date | date:'dd/MM/yyyy hh:mm:ss a' }}</p>
      <div class="d-flex text-end">
        <a href="{{ blogData.link }}" class="btn btn-card justify-content-end">Leer Artículo</a>
      </div>
    </div>
</div>


