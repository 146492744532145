<h2>FORMAS DE PAGO:</h2>
<section id="pagos">
    <div class="row d-flex justify-content-center">
        <div class="col-lg-10 col-md-11 col-sm-11 d-flex justify-content-center">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <!---->
                    <div class="row ">
                        <div class="col-6 mt-5">
                            <img class="americanExpress" src="../../assets/images/A-E.svg" alt="" />
                        </div>
                        <div class="col-6 mt-5">
                            <img class="visa" src="../../assets/images/Visa.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <!---->
                    <div class="row ">
                        <div class="col-12 mt-2">
                            <h2 class="texto">DEPÓSITO Ó TRANSFERENCIA</h2>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <!---->
                    <div class="row ">
                        <div class="col-6 mt-5">
                            <img class="masterCard" src="../../assets/images/Master_card.svg" alt="" />
                        </div>
                        <div class="col-6 mt-5">
                            <img class="clip" src="../../assets/images/Clip.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>