<section *ngIf="dataBlog != undefined" id="blog">
    <div class="row p-0 m-0">
        <div class="col-12 d-block">
           <a class="title text-center" href="https://servicios2.gestocar.mx/blog/">Blog</a>
        </div>
        <div class="col-12">
            <div class="carousel-container">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let blog of dataBlog" >
                        <app-card-blog-carousel [blogData]="blog" class="d-flex aling-items-center justify-content-center pt-3 pb-3"></app-card-blog-carousel>
                    </ng-template>                    
                </owl-carousel-o> 
            </div>         
        </div>
    </div>
</section>
