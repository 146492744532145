<section class="section-image d-flex align-items-center" id="search-record">  
  <div class="row justify-content-center p-0 m-0 w-100">
    <div class="col-12 col-sm-8 col-lg-7 myCol">
      <div class="row justify-content-center p-0 m-0 w-100">
        <div class="col-12 col-sm-11 col-lg-10">
          <h1>Buscador de Tramites:</h1>
          <span>Selecciona el estado y el tramite que busques:</span>          
          <label for="state">Estado:</label>
          <select id="state" (change)="updateSelect()">
            <option value="CDMX" selected>CDMX</option>
            <option value="EDOMEX">EDOMEX</option>
            <option value="MORELOS">MORELOS</option>
          </select>
          <div *ngIf="procedures != undefined">
            <label for="state">Tramite:</label>
            <input placeholder="Escriba o seleccione para buscar" id="id_procedure" list="procedures" class="inputBarcodeField">
              <datalist id="procedures"> 
                  <option class="ml-5" *ngFor="let procedure of procedures" value="{{procedure.id + 1}} - {{ procedure.name }}"></option>                                 
              </datalist>  
          </div>                  
          <span class="mt-4 mb-4 recommendations" (click)="showRecommendation()"> Toma en cuenta estas recomendaciones.</span>
          <div class="d-block w-100 text-end button mb-4 mb-md-0">
            <button class="btn btn-search px-4 pb-3" (click)="showDetails()">REQUISITOS</button>
          </div>  
        </div>
      </div>       
    </div>    
  </div>  
</section>