<section id="contacto">
  <div class="row my-row d-flex justify-content-center">
    <div class="myCol col-lg-10 col-md-11 col-sm-11 d-flex justify-content-center">
      <div class="card">
           
            <form [formGroup]="formContact" (ngSubmit)="onSubmit()">
                <h2>Contactanos:</h2>
                <div class="mb-3">
                  <label for="name" class="form-label">Nombre completo</label>
                  <input 
                      [ngClass]="{
                        'is-invalid': formContact.controls['name'].invalid && formContact.controls['name'].touched,
                        'is-valid': formContact.controls['name'].valid && formContact.controls['name'].touched
                      }"
                      type="text" 
                      class="form-control" 
                      formControlName="name"
                  >
                  <div class="invalid-feedback text-start p-0 m-0 w-60" style="color: red;">El nombre es un campo obligatorio</div>
                </div>
                <div class="mb-3">
                  <label for="phone" class="form-label">Télefono</label>
                  <input 
                      [ngClass]="{
                        'is-invalid': formContact.controls['phone'].invalid && formContact.controls['phone'].touched,
                        'is-valid': formContact.controls['phone'].valid && formContact.controls['phone'].touched
                      }"
                      type="text" 
                      class="form-control" 
                      formControlName="phone"
                      pattern="[0-9]{10}"
                  >
                  <div class="invalid-feedback text-start p-0 m-0 w-60" style="color: red;">El teléfono es un campo obligatorio, númerico y de 10 dígitos</div>
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label">Correo electrónico</label>
                  <input 
                      [ngClass]="{
                        'is-invalid': formContact.controls['email'].invalid && formContact.controls['email'].touched,
                        'is-valid': formContact.controls['email'].valid && formContact.controls['email'].touched
                      }"
                      type="email" 
                      class="form-control" 
                      formControlName="email"
                  >
                  <div class="invalid-feedback text-start p-0 m-0 w-60" style="color: red;">El correo electrónico es un campo obligatorio</div>
                </div>
                <div class="mb-3">
                    <label for="subject" class="form-label">Asunto</label>
                    <input 
                        [ngClass]="{
                          'is-invalid': formContact.controls['subject'].invalid && formContact.controls['subject'].touched,
                          'is-valid': formContact.controls['subject'].valid && formContact.controls['subject'].touched
                        }"
                        type="text" 
                        class="form-control" 
                        formControlName="subject"
                    >
                    <div class="invalid-feedback text-start p-0 m-0 w-60" style="color: red;">El asunto es un campo obligatorio</div>
                  </div>
                  <div class="mb-3">
                    <label for="message" class="form-label">Mensaje</label>
                    <textarea 
                        [ngClass]="{
                          'is-invalid': formContact.controls['message'].invalid && formContact.controls['message'].touched,
                          'is-valid': formContact.controls['message'].valid && formContact.controls['message'].touched
                        }"
                        type="text" 
                        class="form-control" 
                        formControlName="message"
                    ></textarea>
                    <div class="invalid-feedback text-start p-0 m-0 w-60" style="color: red;">El mensaje es un campo obligatorio</div>
                  </div>
                <div class="mb-3 d-block form-check">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">Suscríbase a nuestro boletín informativo</label>
                  <label class="form-check-label">Consulta nuestro <a href="./../../../../assets/aviso_de_privacidad.pdf" target="_blank"> Aviso de Privacidad</a> </label>
                </div>
                <button type="submit" class="btn btn-enviar" [disabled]="formContact.invalid">Enviar</button>
              </form>

                <div class="ubicacion">
                  <iframe class="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.908793510817!2d-99.15795658561812!3d19.41634634623276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff24c6eda98b%3A0xc0d36f0e0fa1b118!2sZacatecas%20%2324-int%20102%2C%20Roma%20Nte.%2C%20Cuauht%C3%A9moc%2C%2006700%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1658418745350!5m2!1ses-419!2smx" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <p>Ubicación:<br>
                    Zacatecas #24-int 102, Roma Nte., Cuauhtémoc,<br>
                    06700 Ciudad de México, CDMX<br><br>
                    
                    Email:<br>
                    soygestocar@gmail.com<br><br>
                    
                    Telefonos:<br>
                    (55) 4025-0459<br><br>
                    
                    (55) 5646-1132</p>

                </div>
      </div>
    </div> 
  </div>
</section>