import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiService } from '@data/services/api/ui.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private document: any,
    private uiService: UiService,
  ) { }

  private url: string = environment.urlFolio;
  formFolio: FormGroup = this.formBuilder.group({
    // Datos
    folio:         [null, [Validators.required]],
    email:         [null, [Validators.required, Validators.email]]   
  });

  ngOnInit(): void {
  }

  onSubmit(): void {
    if(this.formFolio.value.folio && this.formFolio.value.email){
      let link = this.url + 'estatusFolio?folio=' + this.formFolio.value.folio + '&op=' + this.formFolio.value.email;
      this.document.location.href = link;
    }else{
      this.uiService.showNotification('error','Por favor, asegúrese de haber introducido el número de folio y el correo electrónico.')
    }    
  }
} 