import { Component, Input, OnInit } from '@angular/core';
import { BlogI } from '@data/interfaces/blog';

@Component({
  selector: 'app-card-blog-carousel',
  templateUrl: './card-blog-carousel.component.html',
  styleUrls: ['./card-blog-carousel.component.css']
})
export class CardBlogCarouselComponent implements OnInit {

  constructor() { }
  
  @Input() blogData!: any;
  ngOnInit(): void {
    //console.log('Blog data llegó: ', this.blogData);
  }

  get_url(content: string){
    let re = /http([^"'\s]+)/g,
    encontrados = content.match(re);
    return !encontrados ? 'https://dunlite.com.au/wp-content/uploads/2019/04/placeholder.jpg' : encontrados[0];
}


}
