<section id="certification">
    <div class="row d-flex justify-content-center">
        <div class="col-lg-10 col-md-11 col-sm-11 d-flex justify-content-center">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 d-flex justify-content-center">
                    <!---->
                    <img class="img-fluid" src="../../assets/images/distintivo-oro.png" alt="" />
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12">
                    <!---->
                    <div class="row ">
                        <div class="col-4">
                            <h2>+10</h2>
                            <p>Laboramos en<br> mas de 12 estados</p>
                        </div>
                        <div class="col-4">
                            <h2>+30K</h2>
                            <p>Mas de Treinta mil<br> clientes nos prefieren</p>
                        </div>
                        <div class="col-4">
                            <h2>+700</h2>
                            <p>Convenio con<br> mas de 700 empresas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Inicia Contenido
<section id="certification">
    <div class="row d-flex justify-content-center">
        <div class="col-lg-10 col-md-10 col-sm-10 d-flex justify-content-center">
            <div class="card">
                <div class="row">
                    <div class="col-lg-12 col-md-11 col-sm-11" style="background-color: red;">
                        <img class="img-fluid" src="../../assets/images/distintivo-plata.png" alt="" />
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-12" style="background-color: green;">
                        <div class="row" style="background-color: brown;">
                            <div class="col-lg-3 col-md-3 col-sm-4 mt-3" style="background-color: blue;">
                                <h2>+10</h2>
                                <p>Laboramos en<br> mas de 12 estados</p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-4 mt-3" style="background-color: pink;">
                                <h2>+30K</h2>
                                <p>Mas de Treinta mil<br> clientes nos prefieren</p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-4 mt-3" style="background-color: purple;">
                                <h2>+700</h2>
                                <p>Convenio con<br> mas de 700 empresas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<!--
<div class="contenedor">
    <div class="row row-cols-4 justify-content-md-center certificacion">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <img class="plata" src="../../assets/images/distintivo-plata.png" alt="" />
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <h2>+10</h2>
            <p>Laboramos en<br> mas de 12 estados</p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <h2>+30K</h2>
            <p>Mas de Treinta mil<br> clientes nos prefieren</p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <h2>+700</h2>
            <p>Convenio con<br> mas de 700 empresas</p>
        </div>
      </div>
</div>-->
<!--Fin Contenido-->