<section class="d-block mb-5" id="clients">
  <div class="row d-flex justify-content-center">
    <div class="col-11 d-flex justify-content-center">
      <div class="card w-100">
        <div class="row">
          <div class="col-12">
            <p class="texto">¿Ya eres cliente y deseas conocer el estatus de tu folio?</p>
            <p class="texto-sub">Llena el siguiente formulario y haz click en consultar:</p>
          </div>
          <div class="col-lg-5 col-md-7 col-sm-12 offset-lg-1">
            <form class="consulta" [formGroup]="formFolio" (ngSubmit)="onSubmit()">
              <div class="row justify-content-end">
                <div class="col-lg-10 col-md-10 col-sm-12 mt-3 text-md-end text-center">
                  <div class="text-start">
                    <label for="folio" class="form-label aling-end">Numero de folio:</label>
                    <input [ngClass]="{
                              'is-invalid': formFolio.controls['folio'].invalid && formFolio.controls['folio'].touched,
                              'is-valid': formFolio.controls['folio'].valid && formFolio.controls['folio'].touched
                            }" type="number" class="form-control w-100" formControlName="folio">
                    <div class="invalid-feedback text-start p-0 m-0 w-60">El folio es un campo obligatorio</div>
                  </div>

                  <div class="text-start">
                    <label for="correo" class="form-label mt-3">Correo Eléctronico:</label>
                    <input [ngClass]="{
                              'is-invalid': formFolio.controls['email'].invalid && formFolio.controls['email'].touched,
                              'is-valid': formFolio.controls['email'].valid && formFolio.controls['email'].touched
                            }" type="email" class="form-control w-100" formControlName="email">
                    <div class="invalid-feedback text-start p-0 m-0 w-60">Introduce un correo válido.</div>

                  </div>
                  <button type="submit" class="btn btn-consulta mb-4 mt-5 mt-md-3 align-self-end">CONSULTAR</button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-12 car d-flex align-items-center justify-content-center">
            <img class="img-fluid" src="../../assets/images/White Mclaren.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>