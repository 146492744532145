import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ProceduresService } from '@data/services/api/procedures.service';
import { UiService } from '@data/services/api/ui.service';


@Component({
  selector: 'app-search-record',
  templateUrl: './search-record.component.html',
  styleUrls: ['./search-record.component.css']
})
export class SearchRecordComponent implements OnInit {

  procedures: any;
  @Output() onNewProcedure: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNewRecommendation: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private uiService: UiService,
    private proceduresService: ProceduresService,
    
    @Inject(DOCUMENT) document: Document
  ) { }

  async ngOnInit() {
    this.updateSelect()
  }

  async updateSelect(){
    let myState = (document.getElementById('state') as HTMLInputElement).value;
    if(myState){
        this.getProcedures(myState);
    }else{
      this.procedures = undefined;
      this.uiService.showNotification('error','Ocurrio un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.');
      
    }
  }
  
  async getProcedures(state: string){
    let getProcedures: any;
    getProcedures = await this.proceduresService.getProceduresByState(state);
    if(getProcedures.ok){
      this.procedures = getProcedures.info;
    }else{
      this.procedures = undefined;
      this.uiService.showNotification('error','Ocurrio un error al tratar de procesar la solicitud, por favor, inténtelo más tarde.')
    }
  }
  
  showRecommendation(): any {
    this.onNewRecommendation.emit();
  }

  showDetails(): any{
      let myState = (document.getElementById('state') as HTMLInputElement).value;
      let myProcedure = (document.getElementById('id_procedure') as HTMLInputElement).value;
      let id = parseInt(myProcedure.split(" - ")[0]) - 1;
      if( (myState && myProcedure)){
        let title = myProcedure.split(" - ", 2);
        let data = this.proceduresService.getRequirements(myState, id);
        data.procedure= title[1];
        this.onNewProcedure.emit(data);        
      }else{
        this.uiService.showNotification('error','Por favor, asegúrese de haber seleccionado un Estado y un trámite e inténtelo nuevamente.')
      }
  }

}
