import { ProcedureI } from "@data/interfaces/procedures";

export const tramitesPorEstado = {
    'CDMX': [
        {
            id: 0,
            name: 'Alta de placas vehículo nuevo'
        },
        {
            id: 1,
            name: 'Alta de vehículo de otra entidad'
        },
        {
            id: 2,
            name: 'Alta de placas para motocicleta'
        },
        {
            id: 3,
            name: 'Placas para vehículos de carga y remolques'
        },
        {
            id: 4,
            name: 'Cambio de propietario'
        },
        {
            id: 5,
            name: 'Renovación de tarjeta de circulación'
        },
        {
            id: 6,
            name: 'Holograma tipo exento'
        },
        {
            id: 7,
            name: 'Baja de placas'
        },
        {
            id: 8,
            name: '¿Tu placa no está registrada en la secretaria de finanzas?'
        },
        {
            id: 9,
            name: 'Revista vehicular transporte de carga y mercantíl'
        },
        {
            id: 10,
            name: 'Placas y permisos federales para vehículos de carga'
        },
        {
            id: 11,
            name: 'Placas para vehículos clásicos'
        },
        {
            id: 12,
            name: 'Placas para autos para personas con capacidades diferentes'
        },
        {
            id: 13,
            name: '¿Quieres verificar tu auto y no tienes tiempo?'
        },
        {
            id: 14,
            name: '¿Te llevó la grúa?'
        },
        {
            id: 15,
            name: 'Permiso para circular sin placas'
        },
        {
            id: 16,
            name: 'Permisos tipo ANTRA para carga'
        },
        {
            id: 17,
            name: 'Acta ante ministerio público'
        },
        {
            id: 18,
            name: 'Aviso de compra-venta'
        },
        {
            id: 19,
            name: 'Fotomultas'
        },
        {
            id: 20,
            name: 'Aseguramos tu patrimonio con compañías de seguro'
        },
        {
            id: 21,
            name: '¿Piensas vender tu auto? '
        },
        {
            id: 22,
            name: 'Traslado de vehículos en el interior de la república'
        },
        {
            id: 23,
            name: 'Administramos tu flota vehicular'
        },
        {
            id: 24,
            name: 'Realizamos auditorias vehiculares'
        },
        {
            id: 25,
            name: 'Conferencias a colaboradores'
        },
        {
            id: 26,
            name: 'Certificación de conductores'
        },
        {
            id: 27,
            name: 'Convenios'
        },
        {
            id: 28,
            name: 'Alta de placas vehículo hibrido o eléctrico'
        },
        {
            id: 29,
            name: 'Verificación'
        },
        {
            id: 30,
            name: 'Liberación en corralón'
        },
        {
            id: 31,
            name: 'Acreditación de propiedad'
        },
        {
            id: 32,
            name: 'Servicio de mensajería'
        },
        {
            id: 33,
            name: 'Seguro de auto'
        },
        {
            id: 34,
            name: 'Constancia de adeudos para licitación'
        },
        {
            id: 35,
            name: 'Investigación por cambio de estado'
        },
        {
            id: 36,
            name: 'Mensajería'
        },
        {
            id: 37,
            name: 'Traslado de automóvil'
        },
        {
            id: 38,
            name: 'Alta de placas federales'
        }
    ],
    'EDOMEX': [
        {
            id: 0,
            name: 'Alta de placas vehículo nuevo'
        },
        {
            id: 1,
            name: 'Alta en EDOMEX vehículo de otra entidad'
        },
        {
            id: 2,
            name: 'Alta de placas para motocicleta'
        },
        {
            id: 3,
            name: 'Placas para vehículos de carga y remolques'
        },
        {
            id: 4,
            name: 'Cambio de propietario'
        },
        {
            id: 5,
            name: 'Renovación de tarjeta de circulación'
        },
        {
            id: 6,
            name: 'Holograma tipo exento'
        },
        {
            id: 7,
            name: 'Baja de placas'
        },
        {
            id: 8,
            name: 'Revista vehicular transporte de carga y mercantíl'
        },
        {
            id: 9,
            name: 'Placas y permisos federales para vehículos de carga'
        },
        {
            id: 10,
            name: 'Placas para autos para personas con capacidades diferentes'
        },
        {
            id: 11,
            name: '¿Quieres verificar tu auto y no tienes tiempo?'
        },
        {
            id: 12,
            name: '¿Te llevó la grúa?'
        },
        {
            id: 13,
            name: 'Permiso para circular sin placas'
        },
        {
            id: 14,
            name: 'Permisos tipo ANTRA para carga'
        },
        {
            id: 15,
            name: 'Acta ante ministerio público'
        },
        {
            id: 16,
            name: 'Aviso de compra-venta'
        },
        {
            id: 17,
            name: 'Fotomultas'
        },
        {
            id: 18,
            name: 'Aseguramos tu patrimonio con compañías de seguro'
        },
        {
            id: 19,
            name: '¿Piensas vender tu auto?'
        },
        {
            id: 20,
            name: 'Traslado de vehículos en el interior de la república'
        },
        {
            id: 21,
            name: 'Administramos tu flota vehicular'
        },
        {
            id: 22,
            name: 'Realizamos auditorias vehiculares'
        },
        {
            id: 23,
            name: 'Conferencias a colaboradores'
        },
        {
            id: 24,
            name: 'Certificación de conductores'
        },
        {
            id: 25,
            name: 'Convenios'
        },
        {
            id: 26,
            name: 'Alta de placas vehículo hibrido o eléctrico'
        },
        {
            id: 27,
            name: 'Verificación'
        },
        {
            id: 28,
            name: 'Liberación en corralón'
        },
        {
            id: 29,
            name: 'Acreditación de propiedad'
        },
        {
            id: 30,
            name: 'Servicio de mensajería'
        },
        {
            id: 31,
            name: 'Seguro de auto'
        },
        {
            id: 32,
            name: 'Constancia de adeudos para licitación'
        },
        {
            id: 33,
            name: 'Investigación por cambio de estado'
        },
        {
            id: 34,
            name: 'Mensajería'
        },
        {
            id: 35,
            name: 'Traslado de automóvil'
        },
        {
            id: 36,
            name: 'Alta de placas federales'
        }
    ],
    'MORELOS': [
        {
            id: 0,
            name: 'Alta de placas vehículo nuevo'
        },
        {
            id: 1,
            name: 'Alta en Morelos vehículo de otra entidad'
        },
        {
            id: 2,
            name: 'Alta de placas para motocicleta'
        },
        {
            id: 3,
            name: 'Placas para vehículos de carga y remolques'
        },
        {
            id: 4,
            name: 'Cambio de propietario'
        },
        {
            id: 5,
            name: 'Refrendo anual de tarjeta de circulación'
        },
        {
            id: 6,
            name: 'Holograma tipo exento'
        },
        {
            id: 7,
            name: 'Baja de placas'
        },
        {
            id: 8,
            name: 'Revista vehicular transporte de carga y mercantíl'
        },
        {
            id: 9,
            name: 'Placas y permisos federales para vehículos de carga'
        },
        {
            id: 10,
            name: 'Placas para autos para personas con capacidades diferentes'
        },
        {
            id: 11,
            name: '¿Quieres verificar tu auto y no tienes tiempo?'
        },
        {
            id: 12,
            name: '¿Te llevó la grúa?'
        },
        {
            id: 13,
            name: 'Permiso para circular sin placas'
        },
        {
            id: 14,
            name: 'Permisos tipo ANTRA para carga'
        },
        {
            id: 15,
            name: 'Acta ante ministerio público'
        },
        {
            id: 16,
            name: 'Aviso de compra-venta'
        },
        {
            id: 17,
            name: 'Fotomultas'
        },
        {
            id: 18,
            name: 'Aseguramos tu patrimonio con compañías de seguro'
        },
        {
            id: 19,
            name: '¿Piensas vender tu auto?'
        },
        {
            id: 20,
            name: 'Traslado de vehículos en el interior de la república'
        },
        {
            id: 21,
            name: 'Administramos tu flota vehicular'
        },
        {
            id: 22,
            name: 'Realizamos auditorias vehiculares'
        },
        {
            id: 23,
            name: 'Conferencias a colaboradores'
        },
        {
            id: 24,
            name: 'Certificación de conductores'
        },
        {
            id: 25,
            name: 'Convenios'
        },
        {
            id: 26,
            name: 'Alta de placas vehículo hibrido o eléctrico'
        },
        {
            id: 27,
            name: 'Verificación'
        },
        {
            id: 28,
            name: 'Liberación en corralón'
        },
        {
            id: 29,
            name: 'Acreditación de propiedad'
        },
        {
            id: 30,
            name: 'Servicio de mensajería'
        },
        {
            id: 31,
            name: 'Seguro de auto'
        },
        {
            id: 32,
            name: 'Constancia de adeudos para licitación'
        },
        {
            id: 33,
            name: 'Investigación por cambio de estado'
        },
        {
            id: 34,
            name: ' Mensajería'
        },
        {
            id: 35,
            name: 'Traslado de automóvil'
        },
        {
            id: 36,
            name: 'Alta de placas federales'
        }
    ]
}

export const ProceduresListMock ={    
    CDMX:[
        {
            id: 0,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>INE </li><li>Comprobante de domicilio en CDMX</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal </li><li>Comprobante de domicilio </li><li>RFC</li></ol>',
            nota:'<strong>Notas: </strong>Recuerda que para no generar pago de tenencia, debes de realizar tu alta dentro del los primeros 15 días una vez emitida la factura.'
        },
        {
            id: 1,
            subtitulo1:'Persona moral',
            lista1:'<ol><li>Baja de placas del estado en original </li><li>(en su defecto se realiza un pago de bajas administrativas)</li><li>5 ultimas tenencias ó refrendos pagados </li><li>Factura </li><li>INE del dueño del vehículo </li><li>Comprobante de domicilio en CDMX</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Acta constitutiva </li><li>INE representante legal </li><li>Poder notarial </li><li>Comprobante de domicilio (empresa) </li><li>RFC (empresa)</li></ol>',
            nota:'<strong>Notas: </strong>Tienes hasta el 31 de marzo de cada año para ser acreedor al subsidio de tenencia en la CDMX'
        },
        {
            id: 2,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>INE </li><li>Comprobante de domicilio en CDMX</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal </li><li>Comprobante de domicilio </li></ol>',
            nota:'<strong>Notas: </strong>Para no pagar tenencia debes emplacar dentro de los primeros 15 y no rebasar de los $ 250,000.'
        },
        {
            id: 3,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Factura </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal </li><li>RFC empresa </li><li>Comprobante de domicilio </li><li>Póliza de seguro </li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'<strong>Notas: </strong>Te recordamos que en CDMX, debes de realizar tu revista anual. Tanto documental como Físico-mecánica.'
        },
        {
            id: 4,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>INE comprador y vendedor </li><li>Contrato de compra-venta </li><li>6 ultimas tenencias según modelo del auto </li><li>Comprobante de domicilio </li><li>Tarjeta de circulación</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Refactura en su caso </li><li>6 ultimas tenencias según modelo</li><li>Comprobante de domicilio </li><li>RFC de la empresa </li><li>Comprobante de domicilio </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal</li><li>Tarjeta de circulación</li></ol>',
            nota:'<strong>Notas: </strong>En el cambio de propietario, es el cambio de nombre nn la tarjeta de circulación, no podemos hacer modificación de datos del vehículo.'
        },
        {
            id: 5,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>INE dueño </li><li>Comprobante de domicilio </li><li>Tarjeta de circulación </li><li>6 ultimas tenencias pagadas (según modelo del auto)</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Refactura en su caso </li><li>6 ultimas tenencias pagadas</li><li>Tarjeta de circulación</li><li>Acta constitutiva</li><li>RFC empresa </li><li>Comprobante de domicilio</li><li>INE representante legal</li></ol>',
            nota:'<strong>Notas: </strong>En la renovación de tarjeta de circulación los datos de la anterior tarjeta, aparecen tal y cual. No se hace modificación alguna.'
        },
        {
            id: 6,
            subtitulo1:'Persona física',
            lista1:'<ol<li>INE dueño </li<li>Tarjeta de circulación </li<li>Factura o carta factura vigente </li<li>Bitácora de servicio del auto </li<li>Tenencias pagadas </li</ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Tarjeta de circulación </li><li>Factura o carta factura vigente </li><li>Bitácora de servicio del auto </li><li>Tenencias pagadas </li><li>Acta constitutiva</li><li>INE representante legal </li><li>Poder notarial </li><li>RFC de la empresa </li></ol>',
            nota:'<strong>Notas: </strong>El trámite se realiza en 48 horas y el holograma te ampara por 7 años.'
        },
        {
            id: 7,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>INE dueño </li><li>Comprobante de domicilio </li><li>Ultimas 6 tenencias pagadas </li><li>Carta seguro (según el caso)</li><li>Tarjeta de circulación </li><li>Placas del vehículo </li><li>Acta de M.P. si es por Robo</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Re-factura en su caso </li><li>Ultimas 6 tenencias pagadas </li><li>Carta seguro (según el caso) </li><li>Placas del vehículo </li><li>Tarjeta de circulación </li><li>Acta constitutiva </li><li>INE representante legal </li><li>Poder notarial </li><li>Acta de M.P. si es por Robo</li></ol>',
            nota:'<strong>Notas: </strong>Entregamos una baja en original con su pago, firma y sello correspondiente.'
        },
        {
            id: 8,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Debes acudir a la tesorería tributaria con tu documentación en original del vehículo así como tus documentos por alta. Nosotros te apoyamos para hacerlo de una manera correcta'
        },
        {
            id: 9,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Factura </li><li>Tarjeta de circulación vigente</li><li>Póliza de seguro vigente </li><li>Tenencia año en curso </li><li>Acreditación de revista vehicular año anterior </li><li>RFC empresa </li><li>Acta constitutiva </li><li>INE apoderado legal </li><li>Comprobante de domicilio </li><li>Pago de derechos del año en curso</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Recuerda que año con año debes realizar la revista aquí en CDMX de acuerdo al calendario emitido en la gaceta del gobierno.'
        },
        {
            id: 10,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Alta de la empresa ante la SCT </li><li>Placas federales </li><li>Permiso de carga federal </li><li>Licencia federal</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Para la obtención de placas debes contar con un alta ante la Secretaria de Comunicaciones y Transportes y otorgar un poder a nuestro favor para representarte en la misma.'
        },
        {
            id: 11,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Vehículo con 30 años de antigüedad </li><li>90% de originalidad </li><li>Inspección ante el IPN </li><li>Factura </li><li>Tarjetón </li><li>INE </li><li>Comprobante de domicilio </li><li>6 ultimas tenencias pagadas</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Las placas de auto clásico deben estar acompañadas de un holograma del IPN el cual se coloca en el parabrisas de tu auto.'
        },
        {
            id: 12,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Constancia de discapacidad emitida por el DIF. </li><li>INE </li><li>Comprobante de domicilio </li><li>Factura </li><li>6 ultimas tenencias pagadas</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Los requisitos para tramitar el dictamen ante el DIF son: INE-comprobante de domicilio-acta de nacimiento-CURPfotografía infantil y un certificado médico.'
        },
        {
            id: 13,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Acudimos a tu domicilio particular o laboral mientras tu trabajas o realizas tus pendientes nosotros lo llevamos a verificar.'
        },
        {
            id: 14,
            subtitulo1:'Nosotros te apoyamos para la recuperación de tu auto',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Debes presentar la documentación: Licencia vigente, INE vigente, tenencias pagadas, No tener fotomultas, tarjeta de circulación vigente y factura.'
        },
        {
            id: 15,
            subtitulo1:'',
            lista1:'Emitimos permiso del Estado de Guerrero el cual te permite circular sin placas en todo el territorio nacional, valido por 30 día.',
            subtitulo2:'',
            lista2:'',
            nota:'<strong>Notas: </strong>Este permiso NO circula los días viernes y el quinto sábado del mes.'
        },
        {
            id: 16,
            subtitulo1:'',
            lista1:'<ol><li>Factura o carta factura </li><li>INE dueño o representante legal </li><li>Color de la unidad</li><li>Hasta 8 toneladas</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'<strong>Notas: </strong>Si cuentas con un automóvil con placas de particular, este permiso  te ampara para poder transportar carga.'
        },
        {
            id: 17,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'¿Perdiste tus placas o tarjeta de circulación de tu auto?<br> Es necesario levantar tu acta por extravió ante el Ministerio Publico para poder realizar tu trámite para su reposición.'
        },
        {
            id: 18,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Si vas a vender tu auto, asegúrate de dar aviso a la autoridad competente para deslindarte de toda responsabilidad por si el nuevo dueño no realiza el cambio de propietario en tiempo y forma.'
        },
        {
            id: 19,
            subtitulo1:'Aplica para vehículos con placas de: CDMX, EDOMEX y MORELOS',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Obtén el 80% de descuento en foto multas de la CDMX Recuerda que para verificar en el Estado de México o Morelos, es necesario <strong>NO tener adeudos</strong> por multas o infracciones.'
        },
        {
            id: 20,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Nuestras alianzas comerciales con las diversas compañías de seguros, nos posicionan como la mejor opción en contratación de seguros de auto soygestocarseguros@gmail.com'
        },
        {
            id: 21,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Existen formas para garantizar la compra-venta de tu auto. No arriesgues tu patrimonio! Nosotros te asesoramos. Realiza una compra-venta segura.'
        },
        {
            id: 22,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Te apoyamos en el traslado de tu unidad en toda la republica mexicana. Lo recogemos en tu domicilio y lo entregamos en donde nos indiques. Llámanos y solicita tu cotización!'
        },
        {
            id: 23,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Programamos y damos seguimiento a los servicios de mantenimiento preventivos y correctivos de las unidades de su flota para evitar retrasos en su operación y evites  parar la operación dando siempre soluciones fiables.'
        },
        {
            id: 24,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Los pagos que hace tu empresa: ¿Son los correctos? ¿La documentación de tu flotilla está completa? Y los costos con tus proveedores ¿Como están? ¿Tus ODC se cumplen en tiempo y forma?'
        },
        {
            id: 25,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Disminuye la siniestralidad de tu flotilla de autos. Dale la oportunidad a tus colaboradores de conocer y aprender un poco mas!'
        },
        {
            id: 26,
            subtitulo1:'',
            lista1:'Permitenos ofrecer el apoyo a tus choferes en conocimiento viales. Como Proceder en caso de siniestro. Conocimiento del reglamento de transito con una capacitacion constante.',
            subtitulo2:'',
            lista2:'Haremos pruebas de conocimiento del vehiculo y procesos que te permitan saber que tus choferes estan bien capacitados.',
            nota:''
        },
        {
            id: 27,
            subtitulo1:'Realizamos 2 tipos de convenios para las empresas: Convenio-colaboradores y convenio flotilla.',
            lista1:'<ol><li>A la firma de convenio en tu empresa, te ofrecemos un mejor costo sin la necesidad de salir de tu ambiente laboral.</li><li>Te evitamos grandes filas y tramites engorrosos y lo más importante, el ausentarte a trabajar.</li><li>En Gestocar estamos comprometidos a ayudarte a mejorar tu calidad de vida.</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 28,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA</li><li>IDENTIFICACIÓN OFICIAL</li><li>COMPROBANTE DE DOMICILIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 29,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>VEHICULO NUEVO</li><li>FACTURA</li><li>ALTA DE PLACAS</li><li>TARJETA DE CIRCULACIÓN</li><li>IDENTIFICACIÓN DEL PROPIETARIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 30,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA</li><li>TENENCIA EJERCICIO FISCAL ANTERIOR</li><li>INE DEL PROPIETARIO</li><li>TARJETA DE CIRCULACIÓN VIGENTE</li><li>NO FOTOMULTAS</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 31,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA DE LA UNIDAD</li><li>INE DEL PROPIETARIO</li><li>COMPROBANTE DE DOMICILIO DEL PROPIETARIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 32,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>ESPECIFICACIONES DE LA PAQUETERIA</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 33,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA DE LA UNIDAD</li><li>CÓDIGO POSTAL DE DONDE RECIDE EL PROPIETARIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 34,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>RFC de la empresa</li><li>Acta constitutiva</li><li>Comprobante de domicilio</li><li>Registro estatal de contribuyentes</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 35,
            subtitulo1:'Requisitos. (Cualquiera de los siguientes)',
            lista1:'<ol><li>Factura</li><li>Tarjeta de circulación</li><li>Baja de placas</li><li>Pago de tenencia </li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 36,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>Hacer la solicitud</li><li>Convenio con gestocar</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 37,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>Tarjeta de circulación vigente</li><li>Seguro de auto vigente</li><li>Imagen del auto</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 38,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>Registro ante la SCT</li><li>Factura</li><li>Poliza de seguro con RC de $3.000.000 tres millones</li><li>Verificacion de humo</li><li>Poder notariado a favor de Gestocar</li><li>Documentos de la empresa</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        } 
    ],
    MORELOS:[
        {
            id: 0,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>INE </li><li>Comprobante de domicilio</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>Póliza de seguro de auto  </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal </li><li>RFC de al empresa</li><li>Comprobante de domicilio</li></ol>',
            nota:'<strong>Notas: </strong>En este estado no se paga tenencia pero si un refrendo por tarjeta de circulación año con año.'
        },
        {
            id: 1,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Baja del estado de origen </li><li>Factura y refacturas en su caso </li><li>Tenencias o pagos por refrendos </li><li>INE dueño </li><li>Comprobante de domicilio </li><li>Póliza de seguro vigente</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Baja del estado de origen </li><li>Factura </li><li>Re-factura</li><li>Tenencias o pagos por refrendos </li><li>Póliza de seguro de auto </li><li>Acta constitutiva </li><li>Poder notarial </li><li>Comprobante de domicilio </li><li>RFC de la empresa</li></ol>',
            nota:'<strong>Notas: </strong>Las tenencias  de otra entidad se validan aquí en el estado de Morelos, y para su alta debes entregar el juego de placas correspondiente.'
        },
        {
            id: 2,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>INE </li><li>CURP</li><li>Comprobante de domicilio </li><li>Póliza de seguro para moto</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Carta factura si la moto esta a crédito  </li><li>Póliza de seguro para moto </li><li>Acta constitutiva  </li><li>Poder notarial  </li><li>INE representante legal </li><li>Comprobante de domicilio </li><li>RFC</li></ol>',
            nota:'<strong>Notas: </strong>Actualmente en Morelos cuando el dueño del vehículo es de otro estado, nos piden el comprobante de domicilio actual, mismo que vendrá como dirección en la tarjeta de circulación.'
        },
        {
            id: 3,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Factura </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal </li><li>RFC empresa </li><li>Comprobante de domicilio </li><li>Póliza de seguro </li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'<strong>Notas: </strong>En Morelos por alta de placas de carga paga los mismos derechos que un vehículo particular y debes obtener el permiso de carga correspondiente.'
        },
        {
            id: 4,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>Refactura en su caso </li><li>INE comprador y vendedor  </li><li>Refrendo año corriente </li><li>Póliza de seguro  </li><li>Contrato de compra-venta</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Refactura </li><li>Refrendo año corriente </li><li>Póliza de seguro vigente </li><li>Acta constitutiva  </li><li>INE representante legal </li><li>Poder notarial </li></ol>',
            nota:'<strong>Notas: </strong>En el Estado de Morelos, se debe de cubrir un impuesto por adquisición de vehículo del 1.25% sobre valor factura de origen.'
        },
        {
            id: 5,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Tarjeta anterior al año actual </li><li>INE dueño </li><li>Póliza de seguro vigente </li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Tarjeta anterior al año actual </li><li>Póliza de seguro vigente </li><li>Acta constitutiva </li><li>INE representante legal </li><li>Poder notarial </li><li>RFC </li><li>Comprobante de domicilio</li></ol>',
            nota:'<strong>Notas: </strong>La tarjeta de circulación se cambia año con año (refrendo).'
        },
        {
            id: 6,
            subtitulo1:'Persona física',
            lista1:'<ol><li>INE dueño </li><li>Tarjeta de circulación </li><li>Factura o carta factura vigente </li><li>Bitácora de servicio del auto </li><li>Tenencias pagadas </li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Tarjeta de circulación </li><li>Factura o carta factura vigente </li><li>Bitácora de servicio del auto </li><li>Tenencias pagadas </li><li>Acta constitutiva</li><li>INE representante legal </li><li>Poder notarial </li><li>RFC de la empresa </li></ol>',
            nota:'<strong>Notas: </strong>El trámite se realiza en 48 horas y el holograma te ampara por 7 años'
        },
        {
            id: 7,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>INE dueño </li><li>Póliza de seguro vigente  </li><li>Tarjeta de circulación actual</li><li>Placas del vehículo</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Re-factura en su caso </li><li>Póliza de seguro vigente  </li><li>Tarjeta de circulación actual</li><li>Juego de placas </li><li>Acta constitutiva</li><li>INE representante legal</li><li>Poder notarial</li></ol>',
            nota:'<strong>Notas: </strong>Si la baja es por Robo, se debe presentar el acta ante M.P. ratificada y en original.'
        },
        {
            id: 8,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Factura </li><li>Tarjeta de circulación vigente</li><li>Póliza de seguro vigente </li><li>Tenencia año en curso </li><li>Acreditación de revista vehicular año anterior </li><li>RFC empresa </li><li>Acta constitutiva </li><li>INE apoderado legal </li><li>Comprobante de domicilio </li><li>Pago de derechos del año en curso</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Recuerda que año con año debes realizar la revista aquí en CDMX de acuerdo al calendario emitido en la gaceta del gobierno.'
        },
        {
            id: 9,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Alta de la empresa ante la SCT </li><li>Placas federales </li><li>Permiso de carga federal </li><li>Licencia federal</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Para la obtención de placas debes contar con un alta ante la Secretaria de Comunicaciones y Transportes y otorgar un poder a nuestro favor para representarte en la misma.'
        },
        {
            id: 10,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Constancia de discapacidad emitida por el DIF. </li><li>INE </li><li>Comprobante de domicilio </li><li>Factura </li><li>6 ultimas tenencias pagadas</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Los requisitos para tramitar el dictamen ante el DIF son: INE-comprobante de domicilio-acta de nacimiento-CURPfotografía infantil y un certificado médico.'
        },
        {
            id: 11,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Acudimos a tu domicilio particular o laboral mientras tu trabajas o realizas tus pendientes nosotros lo llevamos a verificar.'
        },
        {
            id: 12,
            subtitulo1:'Nosotros te apoyamos para la recuperación de tu auto',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Debes presentar la documentación: Licencia vigente, INE vigente, tenencias pagadas, No tener fotomultas, tarjeta de circulación vigente y factura.'
        },
        {
            id: 13,
            subtitulo1:'',
            lista1:'Emitimos permiso del Estado de Guerrero el cual te permite circular sin placas en todo el territorio nacional, valido por 30 día.',
            subtitulo2:'',
            lista2:'',
            nota:'<strong>Notas: </strong>Este permiso NO circula los días viernes y el quinto sábado del mes.'
        },
        {
            id: 14,
            subtitulo1:'',
            lista1:'<ol><li>Factura o carta factura </li><li>INE dueño o representante legal </li><li>Color de la unidad</li><li>Hasta 8 toneladas</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'<strong>Notas: </strong>Si cuentas con un automóvil con placas de particular, este permiso  te ampara para poder transportar carga.'
        },
        {
            id: 15,
            subtitulo1:'',
            lista1:'¿Perdiste tus placas o tarjeta de circulación de tu auto?<br> Es necesario levantar tu acta por extravió ante el Ministerio Publico para poder realizar tu trámite para su reposición.',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 16,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Si vas a vender tu auto, asegúrate de dar aviso a la autoridad competente para deslindarte de toda responsabilidad por si el nuevo dueño no realiza el cambio de propietario en tiempo y forma.'
        },
        {
            id: 17,
            subtitulo1:'Aplica para vehículos con placas de: CDMX, EDOMEX y MORELOS',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Obtén el 80% de descuento en foto multas de la CDMX Recuerda que para verificar en el Estado de México o Morelos, es necesario <strong>NO tener adeudos</strong> por multas o infracciones.'
        },
        {
            id: 18,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Nuestras alianzas comerciales con las diversas compañías de seguros, nos posicionan como la mejor opción en contratación de seguros de auto soygestocarseguros@gmail.com.'
        },
        {
            id: 19,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Existen formas para garantizar la compra-venta de tu auto. No arriesgues tu patrimonio! Nosotros te asesoramos. Realiza una compra-venta segura.'
        },
        {
            id: 20,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Te apoyamos en el traslado de tu unidad en toda la republica mexicana. Lo recogemos en tu domicilio y lo entregamos en donde nos indiques. Llámanos y solicita tu cotización!'
        },
        {
            id: 21,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Programamos y damos seguimiento a los servicios de mantenimiento preventivos y correctivos de las unidades de su flota para evitar retrasos en su operación y evites  parar la operación dando siempre soluciones fiables.'
        },
        {
            id: 22,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'¿Los pagos que hace tu empresa: Son los correctos? ¿La documentación de tu flotilla, está completa? Y los costos con tus proveedores ¿Cómo están? ¿Tus ODC se cumplen en tiempo y forma?'
        },
        {
            id: 23,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Disminuye la siniestralidad de tu flotilla de autos. Dale la oportunidad a tus colaboradores de conocer y aprender un poco mas!'
        },
        {
            id: 24,
            subtitulo1:'',
            lista1:'Permitenos ofrecer el apoyo a tus choferes en conocimiento viales. Como Proceder en caso de siniestro. Conocimiento del reglamento de transito con una capacitacion constante.',
            subtitulo2:'',
            lista2:'Haremos pruebas de conocimiento del vehiculo y procesos que te permitan saber que tus choferes estan bien capacitados.',
            nota:''
        },
        {
            id: 25,
            subtitulo1:'Realizamos 2 tipos de convenios para las empresas: Convenio-colaboradores y convenio flotilla.',
            lista1:'<ol><li>A la firma de convenio en tu empresa, te ofrecemos un mejor costo sin la necesidad de salir de tu ambiente laboral.</li><li>Te evitamos grandes filas y tramites engorrosos y lo más importante, el ausentarte a trabajar.</li><li>En Gestocar estamos comprometidos a ayudarte a mejorar tu calidad de vida.</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 26,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA</li><li>IDENTIFICACIÓN OFICIAL</li><li>COMPROBANTE DE DOMICILIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 27,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>VEHICULO NUEVO</li><li>FACTURA</li><li>ALTA DE PLACAS</li><li>TARJETA DE CIRCULACIÓN</li><li>IDENTIFICACIÓN DEL PROPIETARIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 28,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA</li><li>TENENCIA EJERCICIO FISCAL ANTERIOR</li><li>INE DEL PROPIETARIO</li><li>TARJETA DE CIRCULACIÓN VIGENTE</li><li>NO FOTOMULTAS</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 29,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA DE LA UNIDAD</li><li>INE DEL PROPIETARIO</li><li>COMPROBANTE DE DOMICILIO DEL PROPIETARIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 30,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>ESPECIFICACIONES DE LA PAQUETERIA</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 31,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA DE LA UNIDAD</li><li>CÓDIGO POSTAL DE DONDE RECIDE EL PROPIETARIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 32,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>RFC de la empresa</li><li>Acta constitutiva</li><li>Comprobante de domicilio</li><li>Registro estatal de contribuyentes</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 33,
            subtitulo1:'Requisitos. (Cualquiera de los siguientes)',
            lista1:'<ol><li>Factura</li><li>Tarjeta de circulación</li><li>Baja de placas</li><li>Pago de tenencia </li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 34,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>Hacer la solicitud</li><li>Convenio con gestocar</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 35,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>Tarjeta de circulación vigente</li><li>Seguro de auto vigente</li><li>Imagen del auto</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 36,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>Registro ante la SCT</li><li>Factura</li><li>Poliza de seguro con RC de $3.000.000 tres millones</li><li>Verificacion de humo</li><li>Poder notariado a favor de Gestocar</li><li>Documentos de la empresa</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        }
    ],
    EDOMEX:[
        {
            id: 0,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>INE </li><li>Comprobante de domicilio en el Edo. México</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal </li><li>Comprobante de domicilio </li><li>RFC</li></ol>',
            nota:'<strong>Notas: </strong>Recuerda que para no generar pago de tenencia, debes de realizar tu alta dentro de los primeros 15 días una vez emitida la factura.'
        },
        {
            id: 1,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Baja de placas del estado en original </li><li>Factura y refactura en su caso </li><li>5 ultimas tenencias ó refrendos pagados </li><li>INE </li><li>Comprobante de domicilio </li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Baja de placas del estado en original  </li><li>(en su defecto se realiza un pago de baja administrativa). </li><li>5 últimas tenencias pagadas  </li><li>Factura y refactura en su caso  </li><li>INE del representante legal </li><li>CURP </li><li>Comprobante de domicilio en EDOMEX</li></ol>',
            nota:'<strong>Notas: </strong>Al emplacar tu auto por primera vez aquí en el estado de México, debes cubrir una tenencia en su totalidad.'
        },
        {
            id: 2,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>INE </li><li>CURP</li><li>Comprobante de domicilio</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Carta factura si el vehículo esta a crédito </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal </li><li>Comprobante de domicilio </li><li>RFC</li></ol>',
            nota:'<strong>Notas: </strong>Para no pagar tenencia debes emplacar dentro de los primeros 15 y no rebasar de los $ 100,000 pesos.'
        },
        {
            id: 3,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Factura </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal </li><li>RFC empresa </li><li>Comprobante de domicilio </li><li>Póliza de seguro </li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'<strong>Notas: </strong>Al realizar un alta se paga el alta, autorización para el transporte de carga y la revista cubriéndose además la tenencia correspondiente.'
        },
        {
            id: 4,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>Refactura en su caso </li><li>INE comprador y vendedor </li><li>Contrato de compra-venta  </li><li>Tenencia ejercicio fiscal corriente  </li><li>Comprobante de domicilio</li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Refactura en su caso </li><li>Acta constitutiva </li><li>Poder notarial </li><li>INE representante legal</li><li>INE representante legal </li><li>Tenencia ejercicio fiscal corriente</li><li>RFC de la empresa </li><li>Comprobante de domicilio</li></ol>',
            nota:'<strong>Notas: </strong>En el Estado de México, se debe de cubrir un impuesto por adquisición de vehículo del 1.25% sobre valor factura de origen.'
        },
        {
            id: 5,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>Tenencia ejercicio fiscal corriente </li><li>INE dueño </li><li>Comprobante de domicilio </li><li>Acta M.P. por extravió </li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Refactura en su caso </li><li>Tenencia ejercicio corriente</li><li>Acta M.P. por extravió</li><li>Acta constitutiva</li><li>INE representante legal </li><li>RFC empresa</li><li>Comprobante de domicilio</li></ol>',
            nota:'<strong>Notas: </strong>La tarjeta de circulación es permanente en este estado.'
        },
        {
            id: 6,
            subtitulo1:'Persona física',
            lista1:'<ol><li>INE dueño </li><li>Tarjeta de circulación </li><li>Factura o carta factura vigente </li><li>Bitácora de servicio del auto </li><li>Tenencias pagadas </li><li>2 testigos INE</li><li>Carta poder </li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura o carta factura vigente  </li><li>Tarjeta de circulación </li><li>Bitácora de servicios </li><li>Acta constitutiva  </li><li>INE representante legal </li><li>Poder notarial  </li><li>RFC </li><li>Comprobante de domicilio  </li><li>2 testigos INE </li><li>Carta poder </li></ol>',
            nota:'<strong>Notas: </strong>Toda la documentación se debe presentar en original ante la Secretaria del Medio Ambiente. La documentación entra a revisión y en 7 días se da contestación.'
        },
        {
            id: 7,
            subtitulo1:'Persona física',
            lista1:'<ol><li>Factura </li><li>INE dueño </li><li>Comprobante de domicilio </li><li>Tenencia ejercicio fiscal vigente  </li><li>Carta seguro (según el caso) </li><li>Tarjeta de circulación </li><li>Placas del vehículo </li></ol>',
            subtitulo2:'Persona moral',
            lista2:'<ol><li>Factura </li><li>Re-factura en su caso </li><li> Tenencia ejercicio fiscal vigente  </li><li>Carta seguro (según el caso) </li><li>Placas del vehículo </li><li>Tarjeta de circulación </li><li>Acta constitutiva </li><li>INE representante legal </li><li> Poder notarial</li></ol>',
            nota:'<strong>Notas: </strong>Si es baja por perdida de placa o Robo de vehículo, debes presentar acta ante M.P. ratificada.'
        },
        {
            id: 8,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Factura </li><li>Tarjeta de circulación vigente</li><li>Póliza de seguro vigente </li><li>Tenencia año en curso </li><li>Acreditación de revista vehicular año anterior </li><li>RFC empresa </li><li>Acta constitutiva </li><li>INE apoderado legal </li><li>Comprobante de domicilio </li><li>Pago de derechos del año en curso</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Recuerda que año con año debes realizar la revista aquí en CDMX de acuerdo al calendario emitido en la gaceta del gobierno.'
        },
        {
            id: 9,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Alta de la empresa ante la SCT </li><li>Placas federales </li><li>Permiso de carga federal </li><li>Licencia federal</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Para la obtención de placas debes contar con un alta ante la Secretaria de Comunicaciones y Transportes y otorgar un poder a nuestro favor para representarte en la misma.'
        },
        {
            id: 10,
            subtitulo1:'Requisitos',
            lista1:'<ol><li>Constancia de discapacidad emitida por el DIF. </li><li>INE </li><li>Comprobante de domicilio </li><li>Factura </li><li>6 ultimas tenencias pagadas</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'Los requisitos para tramitar el dictamen ante el DIF son: INE-comprobante de domicilio-acta de nacimiento-CURPfotografía infantil y un certificado médico.'
        },
        {
            id: 11,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Acudimos a tu domicilio particular o laboral mientras tu trabajas o realizas tus pendientes nosotros lo llevamos a verificar.'
        },
        {
            id: 12,
            subtitulo1:'Nosotros te apoyamos para la recuperación de tu auto.',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Debes presentar la documentación: Licencia vigente, INE vigente, tenencias pagadas, No tener fotomultas, tarjeta de circulación vigente y factura.'
        },
        {
            id: 13,
            subtitulo1:'',
            lista1:'Emitimos permiso del Estado de Guerrero el cual te permite circular sin placas en todo el territorio nacional, valido por 30 día.',
            subtitulo2:'',
            lista2:'',
            nota:'<strong>Notas: </strong>Este permiso NO circula los días viernes y el quinto sábado del mes.'
        },
        {
            id: 14,
            subtitulo1:'',
            lista1:'<ol><li>Factura o carta factura </li><li>INE dueño o representante legal </li><li>Color de la unidad</li><li>Hasta 8 toneladas</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:'<strong>Notas: </strong>Si cuentas con un automóvil con placas de particular, este permiso  te ampara para poder transportar carga.'
        },
        {
            id: 15,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'¿Perdiste tus placas o tarjeta de circulación de tu auto?<br> Es necesario levantar tu acta por extravió ante el Ministerio Publico para poder realizar tu trámite para su reposición.'
        },
        {
            id: 16,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Si vas a vender tu auto, asegúrate de dar aviso a la autoridad competente para deslindarte de toda responsabilidad por si el nuevo dueño no realiza el cambio de propietario en tiempo y forma.'
        },
        {
            id: 17,
            subtitulo1:'Aplica para vehículos con placas de: CDMX, EDOMEX y MORELOS',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Obtén el 80% de descuento en foto multas de la CDMX Recuerda que para verificar en el Estado de México o Morelos, es necesario <strong>NO tener adeudos</strong> por multas o infracciones.'
        },
        {
            id: 18,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Nuestras alianzas comerciales con las diversas compañías de seguros, nos posicionan como la mejor opción en contratación de seguros de auto soygestocarseguros@gmail.com.'
        },
        {
            id: 19,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'Existen formas para garantizar la compra-venta de tu auto. No arriesgues tu patrimonio! Nosotros te asesoramos. Realiza una compra-venta segura.',
            nota:''
        },
        {
            id: 20,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Te apoyamos en el traslado de tu unidad en toda la republica mexicana. Lo recogemos en tu domicilio y lo entregamos en donde nos indiques. Llámanos y solicita tu cotización!'
        },
        {
            id: 21,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Programamos y damos seguimiento a los servicios de mantenimiento preventivos y correctivos de las unidades de su flota para evitar retrasos en su operación y evites  parar la operación dando siempre soluciones fiables.'
        },
        {
            id: 22,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Los pagos que hace tu empresa: ¿Son los correctos? ¿La documentación de tu flotilla, está completa? Y los costos con tus proveedores ¿Cómo están? ¿Tus ODC se cumplen en tiempo y forma?'
        },
        {
            id: 23,
            subtitulo1:'',
            lista1:'',
            subtitulo2:'',
            lista2:'',
            nota:'Disminuye la siniestralidad de tu flotilla de autos. Dale la oportunidad a tus colaboradores de conocer y aprender un poco mas!'
        },
        {
            id: 24,
            subtitulo1:'',
            lista1:'Permitenos ofrecer el apoyo a tus choferes en conocimiento viales. Como Proceder en caso de siniestro. Conocimiento del reglamento de transito con una capacitacion constante.',
            subtitulo2:'',
            lista2:'Haremos pruebas de conocimiento del vehiculo y procesos que te permitan saber que tus choferes estan bien capacitados.',
            nota:''
        },
        {
            id: 25,
            subtitulo1:'Realizamos 2 tipos de convenios para las empresas: Convenio-colaboradores y convenio flotilla.',
            lista1:'<ol><li>A la firma de convenio en tu empresa, te ofrecemos un mejor costo sin la necesidad de salir de tu ambiente laboral.</li><li>Te evitamos grandes filas y tramites engorrosos y lo más importante, el ausentarte a trabajar.</li><li>En Gestocar estamos comprometidos a ayudarte a mejorar tu calidad de vida.</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 26,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA</li><li>IDENTIFICACIÓN OFICIAL</li><li>COMPROBANTE DE DOMICILIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 27,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>VEHICULO NUEVO</li><li>FACTURA</li><li>ALTA DE PLACAS</li><li>TARJETA DE CIRCULACIÓN</li><li>IDENTIFICACIÓN DEL PROPIETARIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 28,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA</li><li>TENENCIA EJERCICIO FISCAL ANTERIOR</li><li>INE DEL PROPIETARIO</li><li>TARJETA DE CIRCULACIÓN VIGENTE</li><li>NO FOTOMULTAS</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 29,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA DE LA UNIDAD</li><li>INE DEL PROPIETARIO</li><li>COMPROBANTE DE DOMICILIO DEL PROPIETARIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 30,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>ESPECIFICACIONES DE LA PAQUETERIA</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 31,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>FACTURA DE LA UNIDAD</li><li>CÓDIGO POSTAL DE DONDE RECIDE EL PROPIETARIO</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 32,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>RFC de la empresa</li><li>Acta constitutiva</li><li>Comprobante de domicilio</li><li>Registro estatal de contribuyentes</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 33,
            subtitulo1:'Requisitos. (Cualquiera de los siguientes)',
            lista1:'<ol><li>Factura</li><li>Tarjeta de circulación</li><li>Baja de placas</li><li>Pago de tenencia </li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 34,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>Hacer la solicitud</li><li>Convenio con gestocar</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 35,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>Tarjeta de circulación vigente</li><li>Seguro de auto vigente</li><li>Imagen del auto</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        },
        {
            id: 36,
            subtitulo1:'Requisitos.',
            lista1:'<ol><li>Registro ante la SCT</li><li>Factura</li><li>Poliza de seguro con RC de $3.000.000 tres millones</li><li>Verificacion de humo</li><li>Poder notariado a favor de Gestocar</li><li>Documentos de la empresa</li></ol>',
            subtitulo2:'',
            lista2:'',
            nota:''
        }
    ]
}