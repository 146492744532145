import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Mail, TokenResponse } from '@data/interfaces/contact.interface';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private headers: HttpHeaders = new HttpHeaders({ 'Content-Type':  'application/json' });
  #url: string = environment.url_mailApi;
  private _uid: string =  environment.uid;
  private _apiKey: string = environment.apiKeyMail;


  constructor(private http: HttpClient) { }  
  
  getMailToken(){
    const headers = new HttpHeaders({ 'X-API-Key': this._apiKey});
    const body = { 'uid': this._uid };
    return this.http.post<TokenResponse>(`${this.#url}/token/`, body, { headers });
  }

  sendMail(name: string, phone: number, correo: string, subject: string, message: string, token: string){    
    const headers = new HttpHeaders({ 'Authorization': token});
    //soygestocar@gmail.com
    const body: Mail = {
      mail_send_to: ['soygestocar@gmail.com'],
      mail_subject: 'Correo contacto Gestocar',
      mail_send_to_cco: [],
      mail_body: `<html lang='es'> <head>  <meta charset='UTF-8'>  <meta http-equiv='X-UA-Compatible' content='IE=edge'>  <meta name='viewport' content='width=device-width, initial-scale=1.0'>  <style>p,a,h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif !important;} h1 { font-size: 30px !important;} h2 { font-size: 25px !important;} h3 { font-size: 18px !important;} h4 { font-size: 16px !important;} p,a { font-size: 15px !important; text-decoration: none !important; color: white;}  .imag { width: 20px; height: 20px;} .contA { margin: 0px 5px 0.afooter; color: #ffffff !important; text-decoration: none; font-size: 13px !important;}  </style> </head>  <body>  <div style='width: 100%; background-color: #F0F0F0;'><div style='padding: 20px 10px 20px 10px;'> <div style='background-color: #E7632E; padding: 10px 0px 10px 0px; width: 100%; text-align: center;'> <h1 style="color: white;"> CONTACTO GESTOCAR </h1> </div> <div style='background-color: #ffffff; padding: 20px 0px 5px 0px; width: 100%; text-align: center;'>  <h2> Datos de contacto y mensaje</h2>  <hr>  <p><strong>Asunto:</strong> ${subject}</p><p><strong>Nombre:</strong> ${name}</p><p><strong>Teléfono:</strong> ${phone}</p><p><strong>Correo:</strong> ${correo}</p><p><strong>Mensaje:</strong> ${message}</p></div> <div style='background-color: #E7632E; color: #ffffff; padding: 5px 0px 0px 0px; width: 100%; text-align: center;'> <p style='text-decoration: none; color: #000000; background-color: #FFFFFF;  padding: 10px 0px 10px 0px; font-size: 12px !important;'>2023© Desarrollado por <a href='https://www.zurco.com.mx/home'>Zurco Designio</a></p> </div></div>  </div> </body>  </html>`,
      mail_send_as: 'Contacto Gestocar <soygestocar@gmail.com>',
      mail_replay_to: `${name} <${correo}>`
    };        
    return this.http.post<any>(`${this.#url}/mail/`, body, { headers })
  } 
}