<section id="menu">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      
      <a class="navbar-brand" href="#"><img class="iconoGestocar" src="../../assets/images/gestocar-icon.png" alt=""></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
  
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item text-center">
            <a class="nav-link" href="#inicio">INICIO</a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link" href="#nosotros">NOSOTROS</a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link" href="#nuestros-servicios">SERVICIOS</a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link" href="#remoto">REMOTO</a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link" href="#alianzas">ALIANZAS</a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link" href="#contacto">CONTACTO</a>
          </li>
          <li class="nav-item text-center">
            <a class="nav-link" href="https://sistema.gestocar.mx/">EMPRESAS</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</section>


<section id="banner">
  <div class="row align-items-center justify-content-center h-100">
    <div class="myCol col-lg-10 col-md-10 col-sm-12 text-center h-100 d-flex align-content-between flex-wrap">
      <div class="col-12">
        <!-- <h2 class="titulo">Realizamos tus trámites vehiculares.</h2>
        <p class="subtitulo">Nosotros lo hacemos por ti.</p> -->
      </div>
      <div class="col-12">
        <div class="text-center d-flex justify-content-center align-items-bottom mt-4">
          <a href="https://www.facebook.com/gestocar.mx/" target="_blank"><i class="fa-brands fa-facebook banner-icon"></i></a>
          <a href="https://www.tiktok.com/@gestocar?" target="_blank"><i class="fa-brands fa-tiktok banner-icon"></i></a>
          <a href="https://www.youtube.com/channel/UCxRfpLEGHBOCOJj-cAG-axA" target="_blank"><i class="fa-brands fa-youtube banner-icon"></i></a>
          <a href="https://www.instagram.com/soygestocar/" target="_blank"><i class="fa-brands fa-instagram banner-icon"></i></a>
          <a href="https://twitter.com/gestocardf" target="_blank"><i class="fa-brands fa-twitter banner-icon"></i></a>
          <a href="https://api.whatsapp.com/send?phone=525540250459" target="_blank"><i class="fa-brands fa-whatsapp banner-icon"></i></a>
        </div>
        <div class="d-block text-center button">
          <button class="btn btn-servicios">SERVICIOS</button>
        </div>
      </div>
    </div>
    </div>
</section>


<!--Inicia Contenido-->

<!--<section id="inicio" style="background-color: red;">
    <img class="mcLaren" src="../../assets/images/McLaren Naranja.png" alt="" />
    <div class="centrado-prin">Realizamos tus trámites vehiculares.</div>
    <div class="centrado-sub">Nosotros lo hacemos por ti</div>
    Botones de contacto
    <button type="button" class="btn btn-servicios">SERVICIOS</button>
    <div class="iconos-contacto">
      <i class="fa-brands fa-facebook"></i>
      <i class="fa-brands fa-tiktok"></i>
      <i class="fa-brands fa-youtube"></i>
      <i class="fa-brands fa-instagram"></i>
      <i class="fa-brands fa-twitter"></i>
      <i class="fa-brands fa-whatsapp"></i>
      <img class="iconos" src="../../assets/images/Logos_RS.svg" alt="">

    </div>
  
  </section>-->

<!--Fin Contenido-->